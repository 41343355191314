<template>
  <div class="profile">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 text-center relative">
        <h1 class="logo-text pt-5">
          Customised <span>Clothing</span>
        </h1>
      </div>
    </div>
    <div v-if="loginNow === false">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 text-center">
          <h3 class="pt-5">
            Reset password
          </h3>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 text-center">
          <p>Please enter your new password below.</p>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-6 medium-offset-3 text-center">
          <input
            v-model="password"
            class="sign-up-form-input"
            type="password"
            required
            placeholder="Password" />
        </div>
      </div>
      <div class="grid-x grid-padding-x pb-10">
        <div class="cell small-6 medium-3 medium-offset-3">
          <button @click="reset" class="button rounded-tall expanded">Send</button>
        </div>
      </div>
    </div>
    <div
      @click="message = ''"
      v-if="message.length > 0"
      class="message">
      {{ message }}
    </div>
    <app-login v-if="loginNow === true" />
  </div>
</template>

<script>
import profileLoggedOut from '@/components/profile/LoggedOut.vue';
import axios from '../axios';

export default {
  name: 'ResetPassword',
  components: {
    appLogin: profileLoggedOut,
  },
  data() {
    return {
      password: '',
      message: '',
      loginNow: false,
    };
  },
  methods: {
    reset() {
      const postData = {};
      postData.password = this.password;
      const hashTest = this.$route.params.hash;
      const customerId = this.$route.params.id;
      axios.post(`/customers/resetPassword/${hashTest}/${customerId}.json`, postData)
        .then((response) => {
          this.message = response.data;
          this.loginNow = true;
        });
    },
  },
};
</script>
